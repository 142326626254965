const header = document.getElementById('header');
const hamberger = document.querySelector('.hamberger');
const gnb = document.getElementById('gnb');
// Event
(function () {
	var unlistenableWindowEvents = {
		click: 1,
		dblclick: 1,
		keyup: 1,
		keypress: 1,
		keydown: 1,
		mousedown: 1,
		mouseup: 1,
		mousemove: 1,
		mouseover: 1,
		mouseenter: 1,
		mouseleave: 1,
		mouseout: 1,
		storage: 1,
		storagecommit: 1,
		textinput: 1
	};

	function indexOf(array, element) {
		var
		index = -1,
		length = array.length;

		while (++index < length) {
			if (index in array && array[index] === element) {
				return index;
			}
		}

		return -1;
	}

	var existingProto = (window.Event && window.Event.prototype) || null;
	window.Event = Window.prototype.Event = function Event(type, eventInitDict) {
		if (!type) {
			throw new Error('Not enough arguments');
		}

		// Shortcut if browser supports createEvent
		if ('createEvent' in document) {
			var event = document.createEvent('Event');
			var bubbles = eventInitDict && eventInitDict.bubbles !== undefined ? eventInitDict.bubbles : false;
			var cancelable = eventInitDict && eventInitDict.cancelable !== undefined ? eventInitDict.cancelable : false;

			event.initEvent(type, bubbles, cancelable);

			return event;
		}

		var event = document.createEventObject();

		event.type = type;
		event.bubbles = eventInitDict && eventInitDict.bubbles !== undefined ? eventInitDict.bubbles : false;
		event.cancelable = eventInitDict && eventInitDict.cancelable !== undefined ? eventInitDict.cancelable : false;

		return event;
	};
	if (existingProto) {
		Object.defineProperty(window.Event, 'prototype', {
			configurable: false,
			enumerable: false,
			writable: true,
			value: existingProto
		});
	}

	if (!('createEvent' in document)) {
		window.addEventListener = Window.prototype.addEventListener = Document.prototype.addEventListener = Element.prototype.addEventListener = function addEventListener() {
			var
			element = this,
			type = arguments[0],
			listener = arguments[1];

			if (element === window && type in unlistenableWindowEvents) {
				throw new Error('In IE8 the event: ' + type + ' is not available on the window object. Please see https://github.com/Financial-Times/polyfill-service/issues/317 for more information.');
			}

			if (!element._events) {
				element._events = {};
			}

			if (!element._events[type]) {
				element._events[type] = function (event) {
					var
					list = element._events[event.type].list,
					events = list.slice(),
					index = -1,
					length = events.length,
					eventElement;

					event.preventDefault = function preventDefault() {
						if (event.cancelable !== false) {
							event.returnValue = false;
						}
					};

					event.stopPropagation = function stopPropagation() {
						event.cancelBubble = true;
					};

					event.stopImmediatePropagation = function stopImmediatePropagation() {
						event.cancelBubble = true;
						event.cancelImmediate = true;
					};

					event.currentTarget = element;
					event.relatedTarget = event.fromElement || null;
					event.target = event.target || event.srcElement || element;
					event.timeStamp = new Date().getTime();

					if (event.clientX) {
						event.pageX = event.clientX + document.documentElement.scrollLeft;
						event.pageY = event.clientY + document.documentElement.scrollTop;
					}

					while (++index < length && !event.cancelImmediate) {
						if (index in events) {
							eventElement = events[index];

							if (indexOf(list, eventElement) !== -1 && typeof eventElement === 'function') {
								eventElement.call(element, event);
							}
						}
					}
				};

				element._events[type].list = [];

				if (element.attachEvent) {
					element.attachEvent('on' + type, element._events[type]);
				}
			}

			element._events[type].list.push(listener);
		};

		window.removeEventListener = Window.prototype.removeEventListener = Document.prototype.removeEventListener = Element.prototype.removeEventListener = function removeEventListener() {
			var
			element = this,
			type = arguments[0],
			listener = arguments[1],
			index;

			if (element._events && element._events[type] && element._events[type].list) {
				index = indexOf(element._events[type].list, listener);

				if (index !== -1) {
					element._events[type].list.splice(index, 1);

					if (!element._events[type].list.length) {
						if (element.detachEvent) {
							element.detachEvent('on' + type, element._events[type]);
						}
						delete element._events[type];
					}
				}
			}
		};

		window.dispatchEvent = Window.prototype.dispatchEvent = Document.prototype.dispatchEvent = Element.prototype.dispatchEvent = function dispatchEvent(event) {
			if (!arguments.length) {
				throw new Error('Not enough arguments');
			}

			if (!event || typeof event.type !== 'string') {
				throw new Error('DOM Events Exception 0');
			}

			var element = this, type = event.type;

			try {
				if (!event.bubbles) {
					event.cancelBubble = true;

					var cancelBubbleEvent = function (event) {
						event.cancelBubble = true;

						(element || window).detachEvent('on' + type, cancelBubbleEvent);
					};

					this.attachEvent('on' + type, cancelBubbleEvent);
				}

				this.fireEvent('on' + type, event);
			} catch (error) {
				event.target = element;

				do {
					event.currentTarget = element;

					if ('_events' in element && typeof element._events[type] === 'function') {
						element._events[type].call(element, event);
					}

					if (typeof element['on' + type] === 'function') {
						element['on' + type].call(element, event);
					}

					element = element.nodeType === 9 ? element.parentWindow : element.parentNode;
				} while (element && !event.cancelBubble);
			}

			return true;
		};

		// Add the DOMContentLoaded Event
		document.attachEvent('onreadystatechange', function() {
			if (document.readyState === 'complete') {
				document.dispatchEvent(new Event('DOMContentLoaded', {
					bubbles: true
				}));
			}
		});
	}
}());


var clickEvent = (function () {
	if ('ontouchstart' in document.documentElement === true) {
		return 'touchstart';
	} else {
		return 'click';
	}
})();
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = function (callback, thisArg) {
		thisArg = thisArg || window;
		for (var i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}
(function () {

  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

(function() {
  var throttle = function(type, name, obj) {
      obj = obj || window;
      var running = false;
      var func = function() {
          if (running) { return; }
          running = true;
           requestAnimationFrame(function() {
              obj.dispatchEvent(new CustomEvent(name));
              running = false;
          });
      };
      obj.addEventListener(type, func);
  };

  /* init - you can init any event */
  throttle("resize", "optimizedResize");
})();


if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}




const depth1 = document.querySelectorAll(".main-menu > li");
const depth2 = document.querySelectorAll(".main-menu.depth2");
depth2.forEach((element)=>{
  gsap.set(element,{autoAlpha:0});
});

function menuOverHandler(event){
  const depth2 = event.target.querySelector('.depth2');
  if(depth2 !== null){
    gsap.to(depth2,{autoAlpha:1})
  }
}
function menuOutHandler(event){
  const depth2 = event.target.querySelector('.depth2');
  if(depth2 !== null){
    gsap.to(depth2,{autoAlpha:0})
  }
  
}

depth1.forEach(element=>{
  element.addEventListener("mouseenter",menuOverHandler);
  element.addEventListener("mouseleave",menuOutHandler);
})


const menuEvent = function(){
	document.body.classList.toggle('open');
	header.classList.toggle('open');
	this.classList.toggle('open');
	gnb.classList.toggle('open');
	gnb.nextElementSibling.classList.toggle('open');
}
//클릭 이벤트
hamberger.addEventListener(clickEvent,menuEvent);



let menuItem = document.querySelectorAll('.global-menu-depth1 > li');

function addClass(elem,className){
  let max = elem.length;
  for(let i=0;i<max; i++){
    elem[i].classList.add(className);
  }
}
function removeClass(elem,className){
  let max = elem.length;
  for(let i=0;i<max; i++){
    elem[i].classList.remove(className);
  }
}

menuItem.forEach(menu =>{menu.addEventListener(clickEvent,function(e){
  this.tg = e.target;
  this.menuList = this.tg.parentNode;

  if(this.tg.nextElementSibling.classList.contains('global-menu-depth2')) {
    e.preventDefault();
    if(this.menuList.classList.contains('active')){
      //클릭한 메뉴에 active가 있을때
      removeClass(menuItem,'on');
      this.menuList.classList.remove('active');
      gsap.fromTo(this.menuList,{
          height: (this.tg.offsetHeight + this.tg.nextElementSibling.offsetHeight) + 30,
        },
        {
          height: this.tg.offsetHeight,
          duration: .4
        });
    } else if(!this.menuList.classList.contains('on')){
      //내가 클릭한 메뉴에 on이 없을때 active가 없을때
      removeClass(menuItem,'on');
      this.menuList.classList.add('on');
      // gsap.to(menuItem,
      //   {
      //     height: this.tg.offsetHeight,
      //     duration: .6,
      //     onStart:()=>{removeClass(menuItem,'active');}
      //   });
      gsap.to(this.menuList,
        {
          height: (this.tg.offsetHeight + this.tg.nextElementSibling.offsetHeight) + 30,
          duration: .4
        });
    } else{
      //클릭한 메뉴에 이미 on이 있을때
      this.menuList.classList.remove('on');
      gsap.to(this.menuList,
        {
          height: this.tg.offsetHeight,
          duration: .4
        });
    }
    
  }

})})


//슬라이더 값을 실시간으로 적용하는 기능
const cursors = document.getElementsByClassName("div-line");
const caseItem = document.getElementsByClassName("case-item");
const caseAfter = document.querySelectorAll(".case-after img");
const caseBefore = document.querySelectorAll(".case-before img");

if(cursors){

	Array.prototype.forEach.call(cursors, function(cursor, i){
		cursor.addEventListener("mousedown",dragDrop);
	});

	Array.prototype.forEach.call(caseItem, function(el, i){
		el.addEventListener("mouseup",(event)=>{
			let left = event.clientX - (el.getBoundingClientRect().left);
			const cursor = Array.from(el.getElementsByClassName('div-line'))[0];
			const af = caseAfter[i].parentNode;
			const width = el.getBoundingClientRect().width;
			
			gsap.to(cursor,{left:left});
			console.log(width);
			gsap.to(af,{width:width-left});
		});
	});

	Array.prototype.forEach.call(caseAfter, (element) => {
		element.ondragstart = function(){return false};
	});
	Array.prototype.forEach.call(caseBefore, (element) => {
		element.ondragstart = function(){return false};
	});
	

	function dragDrop(event) {
		const cursor = this;
		const bf = this.parentNode.querySelector(".case-before");
		const af = this.parentNode.querySelector(".case-after");
		let parentX = cursor.parentNode.getBoundingClientRect().left;
		let parentW = cursor.parentNode.getBoundingClientRect().width;
		//console.log(event.clientX);
		let shiftX = event.clientX - parentX;
		// 공을 pageX, pageY 좌표 중앙에 위치하게 합니다.
		function moveAt(pageX) {
			if(pageX > 0 && pageX < parentW){
				cursor.style.left = pageX + 'px';
			} else{
				document.removeEventListener('mousemove', onMouseMove);
			}

			af.style.width = (parentW-pageX) + 'px';
		}
	
		// 포인터 아래로 공을 이동시킵니다.
		moveAt(shiftX);
	
		function onMouseMove(event) {
			let x = event.clientX - parentX;
			moveAt(x);
		}
	
		// (2) mousemove로 공을 움직입니다.
		document.addEventListener('mousemove', onMouseMove);
	
		// (3) 공을 드롭하고, 불필요한 핸들러를 제거합니다.
		cursor.onmouseup = function() {
			document.removeEventListener('mousemove', onMouseMove);
			cursor.onmouseup = null;
		};
	
	};


}

const playerUmbg = document.getElementById("player_umbg");
var player = null;

if(playerUmbg){
	player = new MediaElement('player_umbg',{
		pluginPath: '../build/',
		//stretching: 'responsive',
	});
}

// Load the IFrame Player API code asynchronously.
var tag = document.createElement('script');
tag.src = "https://www.youtube.com/player_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

// Replace the 'ytplayer' element with an <iframe> and
// YouTube player after the API code downloads.
var player;
var player2;
var mainplayer;

function onYouTubePlayerAPIReady() {
	player = new YT.Player('ytplayer', {
		height: '844',
		width: '1500',
		videoId: 'McUPpr_FG5I'
	});

	player2 = new YT.Player('ytplayer2', {
		height: '844',
		width: '1500',
		videoId: 'zGaXO1PblXA'
	});

	mainplayer = new YT.Player('mainplayer', {
		height: '464',
		width: '824',
		videoId: 'kl5y3c2aEy8'
	});
}


function index(el) {
  if (!el) return -1;
  var i = 0;
  do {
    i++;
  } while (el = el.previousElementSibling);
  return i;
}

function siblings(el){
	Array.prototype.filter.call(el.parentNode.children, function(child){
		return child !== el;
	});
}

let tab = document.getElementById("tabwrap");
function tabHandler(event){
	let tg = event.target;
	let idx = index(tg)-1;
	let tabCnt = document.getElementsByClassName('tab-item');
	let tabCnts = Array.from(tabCnt);

	const tabLst = this.querySelectorAll("li");
	tabLst.forEach(elem=>elem.classList.remove("active"));
	tg.classList.add('active');
	tabCnts.forEach(elem=>elem.classList.remove("active"));
	tabCnts[idx].classList.add('active');
}


if(tab){
	const tabList = document.querySelector(".tab-list");
	tabList.addEventListener("click",tabHandler);

}


var mainVisual = new Swiper('.main-visual', {
	speed:700,
	autoplay: {
		delay: 5000,
	},
	loop:true,
	effect:'fade',
	crossFade:true,
	pagination: {
		el: '.main-visual-sect .swiper-pagination',
		type: 'fraction',
	},
	navigation: {
		nextEl: '.main-ctrls.next',
		prevEl: '.main-ctrls.prev',
	},
});
const progBar = document.getElementsByClassName('main-progress-bar')[0];

mainVisual.on('slideChangeTransitionStart',()=>progBar.classList.remove('start'));

mainVisual.on('slideChangeTransitionEnd',()=>progBar.classList.add('start'));


// main-subject
var mainSubject = new Swiper('.main-subject', {
	speed:700,
	// autoplay: {
	// 	delay: 5000,
	// },
	scrollbar: {
    el: '.prs-subject-sect .swiper-scrollbar',
    draggable: true,
		dragSize:250
  },
	slidesPerView: 2.2,
  spaceBetween: 10,
	breakpoints: {
    // when window width is >= 768px
    768: {
      slidesPerView: 2.2,
      spaceBetween: 10
    },
    // when window width is >= 1024px
    1024: {
      slidesPerView: 2.4,
      spaceBetween: 10
    },
    // when window width is >= 1440px
    1440: {
      slidesPerView: 2.8,
      spaceBetween: 15
    },
		1680: {
      slidesPerView: 3.25,
      spaceBetween: 20
    }
  }
});


// main-event-slide
var mainEvent = new Swiper('.main-event-slide', {
	speed:700,
	// autoplay: {
	// 	delay: 5000,
	// },
	scrollbar: {
    el: '.prs-event-sect .swiper-scrollbar',
    draggable: true,
		dragSize:250
  },
	slidesPerView: 2.2,
  spaceBetween: 10,
	breakpoints: {
    // when window width is >= 768px
    768: {
      slidesPerView: 2.2,
      spaceBetween: 10
    },
    // when window width is >= 1024px
    1024: {
      slidesPerView: 2.4,
      spaceBetween: 10
    },
    // when window width is >= 1440px
    1440: {
      slidesPerView: 2.8,
      spaceBetween: 15
    },
		1680: {
      slidesPerView: 3.25,
      spaceBetween: 20
    }
  }
});



// bna-slide
var bnaSlide = new Swiper('.bna-slide', {
	speed:700,
	autoplay: {
		delay: 5000,
	},
	scrollbar: {
    el: '.prs-bna-sect .swiper-scrollbar',
    draggable: true,
		dragSize:250
  },
	slidesPerView: 1.2,
  spaceBetween: 10,
	breakpoints: {
    // when window width is >= 768px
    768: {
      slidesPerView: 1.2,
      spaceBetween: 10
    },
    // when window width is >= 1024px
    1024: {
      slidesPerView: 1.3,
      spaceBetween: 10
    },
    // when window width is >= 1440px
    1440: {
      slidesPerView: 1.5,
      spaceBetween: 15
    },
		1680: {
      slidesPerView: 1.9,
      spaceBetween: 20
    }
  }
});

const quick = document.getElementById("quick");
const quickBtn = document.getElementById("q-btn");
quickBtn.addEventListener(clickEvent,()=>quick.classList.toggle('open'));

const ytBtns = document.querySelectorAll(".yt-thumb-scroll li");
const ytIframe = document.querySelector(".yt-video-area iframe");
if(ytBtns){
	ytBtns.forEach((btn,index)=>{
		var src = btn.getAttribute('data-yt-url');
		
		btn.addEventListener('click',function(){
			var iframeSrc = 'https://www.youtube.com/embed/'+src;
			ytIframe.setAttribute('src',iframeSrc);
			var tit = btn.querySelector('.yt-tit-txt').textContent;
			var mainTit = document.querySelector('.yt-tit-target');
			mainTit.textContent = tit;
			ytBtns.forEach(btn=>btn.classList.remove('active'));
			btn.classList.add('active');
		});
	})
}

const langWrap = document.getElementById('selectLang');
const langBtn = document.getElementById('langBtn');
langBtn.addEventListener('click',(event)=>langWrap.classList.toggle('open'))