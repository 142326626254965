import "./css/swiper-bundle.min.css";
import "./build/mediaelementplayer.min.css";
import "./css/locomotive-scroll.css";
import "./css/common.css";
import "./css/main.css";
import "./css/sub.css";
import "./css/responsive.css";


import gsap from "./js/plugins/gsap.min.js";
import ScrollToPlugin from "./js/plugins/ScrollToPlugin.min.js";
import ScrollTrigger from "./js/plugins/ScrollTrigger.min.js";
import detectBrowser from "./js/plugins/detectBrowser.js";
import mediaelement from "./build/mediaelement-and-player.min.js";
import particle from "./js/paticle.js";
import common from "./js/common.js";
import scrolljs from "./js/scroll.js";
import main from "./js/main.js";
import sub from "./js/sub.js";
