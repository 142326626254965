const qnaObj = {
  qnaWrap : document.getElementById("qnaWrap"),
  qnaItem: {
    element:document.querySelectorAll(".qna-item"),
    height: heightUpdate(".qna-item"),
  },
  quest:{
    element: document.querySelectorAll(".question"),
    height: heightUpdate(".question"),
  },
  answer:{
    element: document.querySelectorAll(".answer"),
    height: heightUpdate(".answer"),
  },
  state:[]
}

function index(el) {
  if (!el) return -1;
  var i = 0;
  do {
    i++;
  } while (el = el.previousElementSibling);
  return i;
}

(function () {
  var throttle = function (type, name, obj) {
    obj = obj || window;
    var running = false;
    var func = function () {
      if (running) {
        return;
      }
      running = true;
      requestAnimationFrame(function () {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    obj.addEventListener(type, func);
  };

  /* init - you can init any event */
  throttle("resize", "optimizedResize");
})();

function heightUpdate(element){
  const quest = document.querySelectorAll(element);
  const length = quest.length;
  last = [];
  for(let i = 0; i < length; i++){
    last.push(quest[i].offsetHeight);
  }
  return last;
}

function updateQna(){
  const length = qnaObj.qnaItem.element.length;
  last = [];
  for(let i=0; i < length; i++){
    last.push(qnaObj.quest.height[i] + qnaObj.answer.height[i])
  }
  return last;
}

function qnaHandler(event){
  const quest = event.target.closest(".question");
  const qna = event.target.closest(".qna-item");
  const idx = (index(qna))-1;
  if(quest !== null && qnaObj.state[idx] == false){
    //question을 눌렀을때
    qna.classList.toggle('active');
    gsap.to(qna, {
      duration:.2,
      height: qnaObj.quest.height[idx] + qnaObj.answer.height[idx],
      onComplete: () => qnaObj.state[idx] = true
    });
  }
  if(quest !== null && qnaObj.state[idx]){
    //question을 다시 눌렀을때
    qna.classList.toggle('active');
    gsap.to(qna, {
      duration:.2,
      height: qnaObj.quest.height[idx],
      onComplete: () => qnaObj.state[idx] = false
    });
  }
}


// handle event
window.addEventListener("optimizedResize", function() {
	const width = window.innerWidth;
  qnaObj.quest.height = heightUpdate(".question");
  qnaObj.answer.height = heightUpdate(".answer");
  qnaObj.qnaItem.height = updateQna();

  const length = qnaObj.qnaItem.element.length;

  for(let i=0; i < length; i++){
    if(qnaObj.state[i] == true){
      gsap.set(qnaObj.qnaItem.element[i],{height:qnaObj.qnaItem.height[i]});
    } else{
      gsap.set(qnaObj.qnaItem.element[i],{height:qnaObj.quest.height[i]});
    }
  }
});

window.dispatchEvent(new Event('optimizedResize'));

if(qnaObj.qnaWrap){
  const length = qnaObj.qnaItem.element.length;
  qnaObj.state = Array.from({length: length}, () => false);
  
  gsap.to(qnaObj.qnaItem.element[0], {
    duration:.2,
    height: qnaObj.quest.height[0] + qnaObj.answer.height[0],
    onComplete: () => {
      qnaObj.qnaItem.element[0].classList.add('active');
      qnaObj.state[0] = true
    }
  });
  qnaWrap.addEventListener("click",qnaHandler)
}


// faq 여러개
var faq = $('.faq-content');

var faqTab = document.querySelectorAll(".faq-list > li");

faq.each(function(index, item){
  var faqItem = $(item).find('.faq-item');
  var faqAnswer = $(item).find('.answer');
  faqAnswer.slideUp(0);
  $(faqAnswer[0]).slideDown(0);
  $(faqItem[0]).addClass('active');
  faqItem.on('click',function(){
    var answer = $(this).find('.answer');
    answer.slideToggle();
    $(this).toggleClass('active');
  })

});

faqTab.forEach(function(elem,index){
  elem.addEventListener('click',function(e){
    faqTab.forEach(el=>{el.classList.remove('active')});
    this.classList.add('active');
    e.preventDefault();
    gsap.to('.faq-content-wrapper', {
      duration:0,
      x:-(100/faqTab.length)*index + '%'
    });
  });

});
// faqTab.on('click',function(e){
//   e.preventDefault();
//   var link = $(this).find('a');
//   var destination = link.attr('href');

//   gsap.to('.faq-content', {
//     scrollTo: {
//       x: destination,
//       autoKill: false
//     }
//   });

//   console.log(destination);
// });


var interior = new Swiper('.interior-slide',{
  effect:'fade',
  autoplay: {
    delay: 4000,
  },
  loop:true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable:true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

var sysSlide = new Swiper('.sys-slide',{
  autoplay: {
    delay: 4000,
  },
  loop:true,
  navigation: {
    nextEl: '.sub-section-sys-slide .swiper-button-next',
    prevEl: '.sub-section-sys-slide .swiper-button-prev',
  },
});


var qnaSlide = new Swiper('.qna-slide',{
  autoplay: {
    delay: 4000,
  },
  loop:true,
  navigation: {
    nextEl: '.section-qna-slide .swiper-button-next',
    prevEl: '.section-qna-slide .swiper-button-prev',
  },
});

var solutionSlide = new Swiper('.solution-slide',{
  pagination: {
    el: '.tab-slide .swiper-pagination',
    type: 'bullets',
    clickable:true,
  },
});


var vsolutionSpecial2 = new Swiper('.vsolution_special2_slide',{
  autoplay: {
    delay: 4000,
  },
  loop:true,
  navigation: {
    nextEl: '.vsolution-point02 .swiper-button-next',
    prevEl: '.vsolution-point02 .swiper-button-prev',
  },
});

var swiperOptSimple = {
  autoplay: {
    delay: 4000,
  },
  loop:true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable:true,
  },
}

var facialCont = new Swiper('.facial-cont-slide',swiperOptSimple);
var facialCont2 = new Swiper('.facial-cont-slide2',swiperOptSimple);
var simpleSwiper = new Swiper('.hooked-nose-slide',swiperOptSimple);


var revisionSlides = document.querySelectorAll("[class*='revision-slide0']")
Array.prototype.forEach.call(revisionSlides, function(el, i){
  new Swiper(el,{
    allowTouchMove:false,
    navigation: {
      nextEl: `.revision-slide0${i+1} .revision-next-btn`,
      prevEl: `.revision-slide0${i+1} .revision-prev-btn`,
    },
  })
});


var pinlessCtSlider = new Swiper('.pinless-ct-slider',{
  // autoplay: {
  //   delay: 4000,
  // },
  navigation: {
    nextEl: '.pinless-ct-slider .revision-next-btn',
    prevEl: '.pinless-ct-slider .revision-prev-btn',
    disabledClass:'hidden-btn'
  },
});

var pinlessSlider = document.getElementById('pinlessSlider');
if(pinlessSlider){
  var pinlesSkulls = Array.from(document.getElementsByClassName('pinless-skull-wrap'));
  var pinlesSteps = document.querySelectorAll('.pinless-steps-list li');

  pinlesSkulls.forEach((elem)=>{gsap.set(elem,{autoAlpha:0})});
  pinlesSteps.forEach((elem)=>{gsap.set(elem,{autoAlpha:0})});
  gsap.set(pinlesSkulls[0],{autoAlpha:1});
  gsap.set(pinlesSteps[0],{autoAlpha:1});
  function showSkull(elem,index){
    elem.forEach((el)=>{gsap.to(el,{autoAlpha:0})});
    gsap.fromTo(elem[index],{autoAlpha:0},{autoAlpha:1});
    elem.forEach((el)=>{el.classList.remove('active')});
    elem[index].classList.add('active');
  }

  pinlessSlider.addEventListener('change',(e)=>{
    var idx = pinlessSlider.value;
    var value = Number(idx);

    showSkull(pinlesSkulls,value);
    showSkull(pinlesSteps,value);
  });
}