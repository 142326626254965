gsap.registerPlugin(ScrollTrigger);
var clickEvent = (function () {
	if ('ontouchstart' in document.documentElement === true) {
		return 'touchstart';
	} else {
		return 'click';
	}
})();
// Using Locomotive Scroll from Locomotive https://github.com/locomotivemtl/locomotive-scroll

const locoScroll = new LocomotiveScroll({
  el: document.querySelector(".smooth-scroll"),
  smooth: false
});

// each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
locoScroll.on("scroll", ScrollTrigger.update);

ScrollTrigger.scrollerProxy(".smooth-scroll", {
  scrollTop(value) {
    return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
  }, // we don't have to define a scrollLeft because we're only scrolling vertically.
  getBoundingClientRect() {
    return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
  },
  // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
  pinType: document.querySelector(".smooth-scroll").style.transform ? "transform" : "fixed"
});


ScrollTrigger.matchMedia({
	// desktop
	"(min-width: 769px)": function () {
		// setup animations and ScrollTriggers for screens over 800px wide (desktop) here...
		// ScrollTriggers will be reverted/killed when the media query doesn't match anymore.

		//심플한 핀
		let pin = gsap.timeline({
			scrollTrigger: {
				trigger: ".pin-simple-container",
        scroller: ".smooth-scroll",
				start: "-100 top",
				end: 'bottom bottom',
			}
		});
    pin.to('.pin-simple',{
      duration:1.5,
      x:80,
      y:120,
      width:'60%',
      ease: Power3.easeOut,
    })
	},
	// mobile
	"(max-width:768px)": function () {
		// Any ScrollTriggers created inside these functions are segregated and get
		// reverted/killed when the media query doesn't match anymore.

	},
	// all 
	"all": function () {
		// ScrollTriggers created here aren't associated with a particular media query,
		// so they persist.

    // const showAnim = gsap.from('.main-header', { 
    //   yPercent: -100,
    //   paused: true,
    //   duration: 0.2
    // }).progress(1);

    // ScrollTrigger.create({
    //   scroller: ".smooth-scroll",
    //   start: 'top -60',
    //   end: 99999,
    //   onUpdate: (self) => {
    //     self.direction === -1 ? showAnim.play() : showAnim.reverse()
    //   },
    //   toggleClass: {className: 'plus-top', targets: '.main-header, #gnb'}
    // });

    const topBtn = document.getElementById("topBtn");
    gsap.fromTo(topBtn,{autoAlpha:0},{
      scrollTrigger: {
        trigger: "#footer",
        scroller: ".smooth-scroll",
        start: '-100% bottom',
        toggleActions: 'play none none reverse',
        toggleClass: {className: 'plus-bottom', targets: '.quick-wrap, .quick_note_counsel'}
        //markers:true
      },
      autoAlpha:1
    });

    topBtn.addEventListener(clickEvent,(e)=>{
      e.preventDefault();
      const target = document.getElementById('content');
      locoScroll.scrollTo(target);
    });

    const ytScroll = document.querySelector(".yt-thumb-scroll");
    if(ytScroll){
      ytScroll.addEventListener("mouseenter",()=>locoScroll.stop())
      ytScroll.addEventListener("mouseleave",()=>locoScroll.start())
    }
    
    const waypoint = document.getElementsByClassName("waypoint");
    if(waypoint){
      gsap.utils.toArray(waypoint).forEach(function(elem, index){
        var startPoint = elem.getAttribute('data-start');
        var startTrigger = startPoint !== null ? startPoint : 'top 75%';

        ScrollTrigger.create({
          trigger: elem,
          scroller: ".smooth-scroll",
          start: startTrigger,
          end: 'bottom top',
          toggleActions: 'play none none none',
          onEnter:()=>elem.classList.add('active'),
          //markers:true,
          //toggleClass: {className: 'active', targets: elem}
        });
      });
    }/* waypoint가 있을때만 실행 */


    const lineAni = document.getElementsByClassName("headline");
    if(lineAni){
      gsap.utils.toArray(lineAni).forEach(function(elem, index){
        const txtrow = elem.querySelectorAll('.fadeTxt');
        
        txtrow.forEach((el, i)=>{
          const fadeDelay = el.getAttribute('data-fade-delay');
          gsap.fromTo(el,{
            opacity:0
          },{
            scrollTrigger: {
              trigger: elem,
              scroller: ".smooth-scroll",
              start: 'top 75%',
              toggleActions: 'play none none none',
              //markers:true
            },
            opacity:1,
            duration:1.2,
            delay:.2*i + (fadeDelay * 0.001),
            ease: Power1.easeOut,
          })
        });
        
      });
    }/* headline이 있을때만 실행 */


    const revealAni = document.getElementsByClassName("gs_reveal");
    if(revealAni){
      gsap.utils.toArray(revealAni).forEach(function(elem, index){
        gsap.set(elem, {autoAlpha: 0}); // assure that the element is hidden when scrolled into view
        const getDelay = elem.getAttribute('data-gs-delay');
      
        var x = 0,
          y = 60,
          z = -100;
      
        if (elem.classList.contains('reveal_fromDown')) {
          y = -12
        } else if(elem.classList.contains("reveal_fromUp")){
          y = 12;
        } else if (elem.classList.contains("reveal_fromRight")) {
          x = 30;
          y = 0;
          z = 10;
        } else if (elem.classList.contains("reveal_fromLeft")) {
          x = -300;
          y = 0;
          z = 10;
        } 
        gsap.fromTo(elem, {
          x: x,
          y: y,
          z: z,
          autoAlpha: 0
        }, {
          scrollTrigger: {
            trigger: elem.parentNode,
            scroller: ".smooth-scroll",
            start: 'top 66%',
            toggleActions: 'play none none none',
            onEnter:()=>elem.classList.add('active'),
            //toggleClass: {className: 'waypoint', targets: '.main-header, #gnb'}
            //markers:true
          },
          duration: .8,
          x: 0,
          y: 0,
          z: 0,
          autoAlpha: 1,
          ease: Back.easeOut,
          overwrite: "auto",
          delay: getDelay * 0.001
        });
      });
    }/* gs_reveal이 있을때만 실행 */

    gsap.utils.toArray(".parallax").forEach((section, i) => {
      section.bg = section.querySelector(".bg");
      let elemHeight = section.bg.clientHeight;
      section.bg.style.backgroundPosition = "50% 0px"; 
        gsap.to(section.bg, {
          backgroundPosition: `50% ${elemHeight / 2}px`,
          ease: "none",
          scrollTrigger: {
            trigger: section,
            scroller: ".smooth-scroll",
            start: "top top", 
            end: "bottom top",
            scrub: true
          }
        });
    });

	}
});


// each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll. 
ScrollTrigger.addEventListener("refresh", () => locoScroll.update());

// after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
ScrollTrigger.refresh();